<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import unionVideoDetail from "@/views/detailPage/unionVideoDetail.vue";
import {getVideoList} from '@/api'
import Empty from "@/components/Empty.vue";
import MyPagination from "@/components/MyPagination.vue";
export default {
  name: "unionVideo",
  components: { MyPagination, Empty, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      videoList:[],
      queryParams:{
        current:1,
        size:6
      },
      total:0
    };
  },
  mounted() {
    this.breadcrumbList = [
      { title: this.$route.meta.pageName },
      { title: "列表" },
    ];
    this.getList()
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "unionVideoDetail",
        query: {
          id:item.id
        },
      });
    },
    getList() {
      getVideoList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.total = res.data.total
          this.videoList = res.data.records
        }
      })
    },
    changeCurrent(e) {
      this.queryParams.current = e
      this.getList()
    }
  },
};
</script>

<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div v-if="videoList" class="union-video-page">
      <div class="video-list-box" v-if="videoList.length > 0">
        <div
          class="video-item"
          v-for="(item,index) in videoList"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="video-cover">
            <img style="width: 100%;height: 100%" :src="item.image">
            <img
              class="play-icon"
              src="@/assets/image/unionVideo/play-icon.png"
            />
          </div>
          <div class="video-title">{{item.title}} </div>
        </div>
      </div>
      <Empty v-else></Empty>
    </div>
    <MyPagination style="padding:0 180px;box-sizing: border-box;margin-bottom: 34px" v-if="videoList.length > 0" :size="6" :total="total" @current-change="changeCurrent"></MyPagination>
    <Empty v-else></Empty>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.union-video-page {
  padding: 0 $pagePadding;
  margin-top: 40px;
  margin-bottom: 116px;
  min-height: 690px;
  .video-list-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 29px 41px;
    .video-item {
      .video-cover {
        width: 496px;
        height: 279px;
        position: relative;
        cursor: pointer;
        .play-icon {
          width: 49px;
          height: 49px;
          position: absolute;
          bottom: 14px;
          left: 24px;
        }
      }
      .video-title {
        @include ellipsis(auto, 2);
        font-size: 23px;
        margin-top: 16px;
        color: #333;
      }
    }
  }
}
</style>
