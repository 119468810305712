<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page">
      <div class="content-title-box">
        <div class="content-title list-title">{{ $route.meta.pageName }}</div>
      </div>
      <div v-if="newsList.length >0" class="list-content" style="margin-top: 63px">
        <div
          class="list-content-item"
          v-for="(item, index) of newsList"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="list-content-item-title">
            <div class="dot"></div>
            <span class="item-title"
              >{{item.title}}</span
            >
          </div>
          <div class="list-content-item-time">{{ item.createTime.split(' ')[0] }}</div>
        </div>
      </div>
      <MyPagination v-if="newsList.length >0" :total="total"></MyPagination>
      <Empty v-else></Empty>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import { getNewsList } from "@/api";
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty, MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      queryParams:{
        current:1,
        size:10,
        type:''
      },
      newsList:[],
      total:0
    };
  },
  mounted() {
    this.breadcrumbList = [{ title: this.$route.meta.pageName }];
    const pageType =JSON.parse(localStorage.getItem('treeList')).find(item=>item.name === '机关党建')
    this.queryParams.type = pageType.id
    this.getList()
  },
  methods: {
    getList() {
      getNewsList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.newsList = res.data.records
          this.total = res.data.total
        }
      })
    },
    toDetail(item) {
      this.$router.push({
        name: "partyBuildingDetail",
        query: {
          // from: encodeURI(this.$route.meta.pageName),
          from:encodeURI(item.newsTypeList[0].newsTypeName),
          url: encodeURI("/PartyBuilding"),
          type:item.newsTypeList[0].newsTypeId,
          id:item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
