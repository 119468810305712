<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import { getTreeList } from "@/api";
export default {
  components: { MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      contentList: [],
      typeList:[]
    };
  },
  mounted() {
    this.breadcrumbList = [{ title: this.$route.meta.pageName }];
    this.contentList = this.$store.state.serviceCenterList;
    getTreeList({location:2}).then(res=>{
      if (res.code === 200) {
        const fatherArr = ['标准体系']
        res.data.forEach(item=>{
          if (fatherArr.includes(item.name)){
            this.typeList.push(...item.childrenList)
          }
        })
      }
    })
  },
  methods: {
    toContent(v) {
      if (v.label === '助企纾困') {
        window.open('https://www.cnbz.gov.cn/ztxx/zxzt/bzszqskzcfw/index.html',"_blank")
      } else {
        const clickType = this.typeList.find(item=>item.name === v.label)
        console.log(clickType,v,'clicktyope')
        this.$router.push({name:"ServiceCenterList",query:{
            type:clickType.id,
            pageName:encodeURI(clickType.name)
        }})
      }
    },
  },
};
</script>

<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="service-center-box">
      <div
        class="content-item"
        v-for="(item, index) of contentList"
        :key="index"
      >
        <div class="item-cn-title">{{ item.title }}</div>
        <!--        <div class="item-title-box">-->
        <!--          <div class="item-cn-title">{{ item.title }}</div>-->
        <!--          <div class="item-en-title">{{ item.egTile }}</div>-->
        <!--        </div>-->
        <div class="item-list">
          <div
            v-for="(v, i) of item.list"
            :key="i"
          >
            <div
              class="item-list-item"
              @click="toContent(v)"
              :style="{ backgroundImage: `url(${v.bgImg})` }"
            >
            </div>
            <div style="text-align: center;margin-top:10px;font-weight: bold;letter-spacing: 1px">{{v.label}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.service-center-box {
  margin-top: 75px;
  padding: 0 295px;
  margin-bottom: 245px;
  .content-item {
    margin-bottom: 33px;
    padding-bottom: 33px;
    border-bottom: 1px solid #dedede;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .item-cn-title {
      font-size: 30px;
      font-weight: bold;
      color: #474747;
      margin-bottom: 33px;
    }
    .item-title-box {
      height: 214px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 280px;
      margin-right: 20px;
      .item-cn-title {
        font-size: 30px;
        font-weight: bold;
        color: #474747;
        margin-bottom: 16px;
      }
      .item-en-title {
        color: #999;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .item-list {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(3, 1fr);
      .item-list-item {
        height: 287px;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 10px;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: bold;
        color: $themeTextColor;
      }
    }
  }
}
</style>
