<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page">
      <div class="content-title-box">
        <div class="content-title list-title">{{ $route.meta.pageName }}</div>
      </div>
      <div class="list-content" style="margin-top: 63px">
        <div class="list-content-item" @click="toJump(item)" v-for="(item, index) of list" :key="index">
          <div class="list-content-item-title">
            <div class="dot"></div>
            <span class="item-title"
              >{{item.title}}</span
            >
          </div>
          <div class="list-content-item-time">{{item.createTime.split(' ')[0]}}</div>
        </div>
      </div>
      <MyPagination :total="total" @current-change="changeCurrent"></MyPagination>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import {questionnaireList} from '@/api'
export default {
  components: { MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      total:0,
      queryParams:{
        size:8,
        current:1
      },
      list:[]
    };
  },
  mounted() {
    this.breadcrumbList = [{ title: this.$route.meta.pageName }];
    this.getList()
  },
  methods:{
    toJump(item) {
      window.open(item.linkUrl,"_blank")
    },
    getList() {
      questionnaireList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.list = res.data.records
          this.total = res.data.total
        }
      })
    },
    changeCurrent(e) {
      this.queryParams.current = e
      this.getList()
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
