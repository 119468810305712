<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import {getEmailInfo } from '@/api'
export default {
  components: { MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      emailInfo:{}
    };
  },
  mounted() {
    const pageId = this.$route.query.id
    this.breadcrumbList = [
      { title: "领导信箱", url: "/LeaderEmail" },
      { title: this.$route.meta.pageName },
    ];
    getEmailInfo(pageId).then(res=>{
      if (res.code === 200) {
        this.emailInfo = res.data
      }
    })
  },
};
</script>

<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="email-reply-box">
      <div class="reply-content">
        <div class="email-code">信件编码：{{emailInfo.id}}</div>
        <div class="question-user-info">
          <div>咨询人：{{ emailInfo.petitioner }}</div>
          <div>手机号：{{emailInfo.phone}}</div>
          <div>留言时间：{{ emailInfo.createTime }}</div>
        </div>
        <div class="question-title">
          咨询主题：{{ emailInfo.consultationTopic }}
        </div>
        <div class="question-des">
          内容：{{emailInfo.consultationContent}}
        </div>
        <div class="reply-des">
          <div class="reply-box">
            <span class="reply-title">回复：</span>
            <span class="reply-text" v-html="emailInfo.replyContent"></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.email-reply-box {
  padding: 0 $pagePadding;
  margin-top: 61px;
  margin-bottom: 40px;

  .reply-content {
    border: 1px solid #e4e4e4;
    padding: 26px 22px;
    font-size: 20px;
    .email-code {
      color: #707070;
      padding: 0 30px;
    }
    .question-user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #707070;
      margin-top: 13px;
      padding: 0 30px;
    }
    .question-title {
      font-size: 24px;
      color: #333;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 40px;
      padding: 0 30px;
    }
    .question-des {
      padding: 0 30px;
      color: #707070;
      line-height: 40px;
      word-break: break-all;
    }
    .reply-des {
      background: #e3e3e3;
      border-radius: 4px;
      padding: 24px 26px;
      margin-top: 38px;
      .reply-box {
        color: #333;
        .reply-title {
          font-size: 22px;
          font-weight: bold;
        }
        .reply-text {
          font-size: 20px;
          line-height: 45px;
        }
      }
    }
  }
}
</style>
