<template>
  <div>
    <MyBreadcrumb :breadcrumbList="breadcrumbList"></MyBreadcrumb>
    <div class="page-content">
<!--      <PageSlider-->
<!--        v-if="Object.keys(sliderActive).length !== 0"-->
<!--        sliderLogo="work-title-icon"-->
<!--        @sliderClick="sliderClick"-->
<!--        @sliderChildrenClick="sliderChildrenClick"-->
<!--        :sliderList="sliderList"-->
<!--        :propSliderActiveIndex="sliderIndex"-->
<!--        :propsSliderChildrenIndex="sliderChildrenIndex"-->
<!--      ></PageSlider>-->
      <div class="list-box">
        <div class="content-title-box">
          <div class="content-title list-title">{{ sliderActive.name }}</div>
        </div>
        <div v-if="newsList.length >0" class="list-content">
          <div
            class="list-content-item"
            v-for="(item, index) of newsList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="list-content-item-title">
              <div class="dot"></div>
              <span class="item-title"
              >{{item.title}}</span
              >
            </div>
            <div class="list-content-item-time">{{formatTime(item.createTime)}}</div>
          </div>
        </div>
        <Empty v-else></Empty>


        <MyPagination
          v-if="newsList.length >0 &&  sliderActive.name !== '部门动态'"
          @current-change="changeCurrent"
          :total="total"
        ></MyPagination>
      </div>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import PageSlider from "@/components/PageSilder.vue";
import MyPagination from "@/components/MyPagination.vue";
import { getNewsList, getTreeList } from "@/api";
import Empty from "@/components/Empty.vue";
import { formatTime } from "@/utils/publicMethod";

export default {
  components: {
    Empty,
    MyBreadcrumb,
    PageSlider,
    MyPagination,
  },
  data() {
    return {
      breadcrumbList: [],
      sliderList: [],
      sliderIndex: 0,
      sliderActive: {},
      sliderChildrenIndex: 0,
      total:0,
      queryParams:{
        pageSize:8,
        pageNum:1,
        type:''
      },
      newsList:[]
    };
  },
  watch: {
    sliderActive: {
      handler(val, oldVal) {
        this.breadcrumbList = [
          { title: this.$route.meta.pageName },
          { title: val.label },
        ];
      },
      immediate: true,
    },
    sliderIndex: {
      handler(val, oldVal) {},
      immediate: true,
    },
  },
  async mounted() {
    this.sliderList = await this.getTreeList()
    console.log(this.sliderList,'qqq')
    const pageType = decodeURI(this.$route.query.pageType);
    this.sliderChildrenIndex = this.$route.query.sliderChildrenIndex
      ? Number(this.$route.query.sliderChildrenIndex)
      : 0;
    if (pageType !== "undefined" && this.sliderChildrenIndex === -1) {
      this.sliderActive = this.sliderList.find(
        (item, index) => item.name === pageType
      );
      this.sliderIndex = this.sliderList.findIndex(
        (item, index) => item.name === pageType
      );
    } else if (this.sliderChildrenIndex !== -1) {
      this.sliderActive = this.sliderList[3];
      this.sliderIndex = 3;
    } else {
      this.sliderActive = this.sliderList[0];
    }
    this.queryParams.type = this.sliderActive.id
    this.getNewsList()

  },
  methods: {
    formatTime,
    sliderClick(e) {
      this.sliderActive = e;
      this.queryParams.type = this.sliderActive.id
      this.getNewsList()
    },
    getTreeList() {
      return new Promise((resolve,reject)=>{
        getTreeList({location:4}).then(res=>{
          resolve(res.data)
        })
      })
    },
    sliderChildrenClick(e) {
      this.sliderChildrenIndex = e;
    },
    getNewsList() {
      getNewsList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.newsList = res.data.list
          this.total = res.data.total
        }
      })
    },
    changeCurrent(e) {
      this.queryParams.pageNum = e
      this.getNewsList()
    },
    toDetail(item) {
      console.log(item,this.sliderActive,'qqq')
      this.$router.push({
        name: "NewsDetail",
        query: {
          from: encodeURI(this.sliderActive.name),
          sliderChildrenIndex: this.sliderChildrenIndex,
          id:item.id,
          type:this.sliderActive.id,
          fromUrl:'integrityServe',
          fromUrlName:'诚信服务'
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.page-content {
  padding: 0 $pagePadding 20px $pagePadding;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: 42px;
  .list-box {
    flex: 1;
    margin-left: 34px;
    .list-content {
      margin-top: 63px;
      min-height: 586px;
      .list-content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19px;
        padding-bottom: 16px;
        border-bottom: 1px solid #f2f2f2;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        .list-content-item-title {
          color: #686868;
          flex: 1;
          display: flex;
          align-items: center;
          .dot {
            margin-right: 9px;
            width: 8px;
            height: 8px;
            background: $dotColor;
            border-radius: 50%;
          }
          .item-title {
            @include ellipsis(1050px);
            cursor: pointer;
            &:hover {
              color: $themeTextColor;
            }
          }
        }
        .list-content-item-time {
          color: #686868;
        }
      }
    }
  }
}
</style>
