<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page">
      <div class="content-title-box">
        <div class="content-title list-title">{{ pageName }}</div>
      </div>
      <div v-if="newsList.length > 0" class="list-content" style="margin-top: 63px">
        <div
          class="list-content-item"
          v-for="(item, index) of newsList"
          :key="index"
        >
          <div class="list-content-item-title" @click="toDetail(item)">
            <div class="dot"></div>
            <span class="item-title"
            >{{item.title}}</span
            >
          </div>
          <div class="list-content-item-time">{{formatTime(item.createTime)}}</div>
        </div>
      </div>
      <MyPagination v-if="newsList.length > 0" :total="total" @current-change="changeCurrent"></MyPagination>
      <Empty v-else></Empty>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import { getNewsList } from "@/api";
import Empty from "@/components/Empty.vue";
import { formatTime } from "@/utils/publicMethod";

export default {
  components: { Empty, MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      queryParams:{
        pageSize:8,
        pageNum:1,
        type:''
      },
      newsList:[],
      total:0,
      pageName:''
    };
  },
  mounted() {
    this.pageName = decodeURI(this.$route.query.pageName)
    this.queryParams.type = this.$route.query.type
    this.breadcrumbList = [
      { title: "标准体系", url: "/ServiceCenter" },
      { title: this.pageName },
    ];
    if (this.queryParams.type && this.pageName) {
      this.getNewsList()
    }
  },
  methods: {
    formatTime,
    changeCurrent(e) {
      this.queryParams.pageNum = e
      this.getNewsListMethod()
    },
    toDetail(item) {
      console.log(this.queryParams.type,this.pageName)
      this.$router.push({
        name: "serviceDetail",
        query: {
          from: encodeURI(this.pageName),
          url: encodeURI(`/ServiceCenter/list?type=${this.queryParams.type}&pageName=${encodeURI(this.pageName)}`),
          id:item.id,
          type:this.queryParams.type
        },
      });
    },
    getNewsList() {
      getNewsList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.newsList = res.data.list
          this.total = res.data.total
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
