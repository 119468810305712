import request from "@/utils/request";

//获取banner列表
export function getBanner(data) {
  return request({
    url: "/website/api/banner/list",
    params: data,
    method: "get",
  });
}

//获取新闻列表
export function getNewsList(data) {
  return request({
    url: "/website/api/news/list",
    params: data,
    method: "get",
  });
}

//获取新闻详情
export function getNewsDetail(data) {
  return request({
    url: "/website/api/news/oneInfo",
    params: data,
    method: "get",
  });
}
//获取树形列表
export function getTreeList(data) {
  return request({
    url: "/website/api/type/treeList",
    params: data,
    method: "get",
  });
}

//工会视频列表
export function getVideoList(data) {
  return request({
    url: "/website/api/video/list",
    params: data,
    method: "get",
  });
}

//工会视频详情
export function getVideoInfo(id) {
  return request({
    url: `/website/api/video/${id}`,
    method: "get",
  });
}


//获取专题列表
export function getTopicList(data) {
  return request({
    url: '/website/api/topic/list',
    method: "get",
    params:data
  });
}
//根据主题id获取绑定的文章
export function getTopicNewsList(data) {
  return request({
    url: '/website/api/topic/newsList',
    method: "get",
    params:data
  });
}
//获取友情链接列表
export function linkList(data) {
  return request({
    url: '/website/api/link/list',
    method: "get",
    params:data
  });
}

//获取领导列表
export function getLeaderList() {
  return request({
    url: '/website/api/leader/list',
    method: "get",
  });
}
//获取部门列表
export function getDepartmentList(type) {
  return request({
    url: `/website/api/department/list/${type}`,
    method: "get",
  });
}
//获取职责内容
export function getDutyList() {
  return request({
    url: '/website/api/duty/list',
    method: "get",
  });
}

//获取图形验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get',
  })
}
//验证输入图形验证码是否正确
export function verifyCaptcha(data) {
  return request({
    url: '/verifyCaptcha',
    method: 'get',
    params:data
  })
}

//获取信箱列表
export function mailboxList(data) {
  return request({
    url: '/website/api/mailbox/list',
    method: 'get',
    params:data
  })
}
//发送手机验证码
export function sendSmsCode(data) {
  return request({
    url: '/sendSmsCode',
    method: 'get',
    params:data
  })
}
//写信
export function sendEmail(data) {
  return request({
    url: '/website/api/mailbox',
    method: 'post',
    data
  })
}
//获取信件详情
export function getEmailInfo(id) {
  return request({
    url: `/website/api/mailbox/${id}`,
    method: 'get',
  })
}
//问卷调查列表
export function questionnaireList(data) {
  return request({
    url: '/website/api/questionnaire/list',
    method: 'get',
    params:data
  })
}

//基础管理
export function basicsInfo(id){
  return request({
    url:`/website/api/setting/${id}`,
    method:'get'
  })
}
