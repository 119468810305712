<template>
  <div class="slider-box">
    <img
      class="slider-logo"
      :src="require(`../assets/image/common/${sliderLogo}.png`)"
    />
    <div
      class="slider-item"
      @click="sliderClick(item, index)"
      v-for="(item, index) of sliderList"
      :key="index"
    >
      <div
        class="slider-item-label"
        :class="{ active: sliderActiveIndex === index }"
      >
        <span>{{ item.name }}</span>
        <img
          class="right-icon"
          src="@/assets/image/common/arrowRight-icon.png"
        />
      </div>
      <div
        class="slider-children-box"
        v-if="item.childrenList && sliderActiveIndex === index"
      >
        <div
          class="slider-children-item"
          :class="{ active: sliderChildrenIndex === i }"
          v-for="(child, i) of item.childrenList"
          :key="i"
          @click.stop="sliderChildrenClick(child, i)"
        >
          {{ child.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageSlider",
  props: {
    sliderLogo: {
      type: String,
      default: undefined,
    },
    sliderList: {
      type: Array,
      default: [],
    },
    propSliderActiveIndex: {
      type: Number,
      default: 0,
    },
    propsSliderChildrenIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sliderActiveIndex: 0,
      sliderChildrenIndex: 0,
    };
  },
  mounted() {
    this.sliderActiveIndex = this.propSliderActiveIndex;
    this.sliderChildrenIndex = this.propsSliderChildrenIndex;
  },
  methods: {
    sliderClick(item, index) {
      // if (item.childrenList) {
      //   this.sliderChildrenIndex = 0;
      //   this.$emit("sliderClick", item.childrenList[0]);
      //   this.$emit("sliderChildrenClick", 0);
      // } else {
      //   this.$emit("sliderClick", item);
      //   this.$emit("sliderChildrenClick", -1);
      // }
      this.$emit("sliderClick", item);
      this.$emit("sliderChildrenClick", -1);
      this.sliderActiveIndex = index;
    },
    sliderChildrenClick(item, index) {
      this.sliderChildrenIndex = index;
      this.$emit("sliderClick", item);
      this.$emit("sliderChildrenClick", index);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.slider-box {
  display: flex;
  flex-direction: column;
  width: 307px;
  .slider-logo {
    width: 100%;
    height: 92px;
  }
  .slider-item {
    .slider-item-label {
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      justify-content: space-between;
      padding: 15px 33px 15px 18px;
      font-size: 20px;
      color: #7d7d7d;
      font-weight: bold;
      background: #f8f8f8;
      margin-top: 20px;
      cursor: pointer;
      box-sizing: border-box;
      &.active {
        color: #474747;
        border-bottom: 2px solid $themBorderColor;
      }
    }
    .right-icon {
      width: 8px;
      height: 15px;
    }
    .slider-children-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      margin-top: 10px;
      .slider-children-item {
        height: 55px;
        text-align: center;
        line-height: 55px;
        background: #e6e4e4;
        color: #474747;
        cursor: pointer;

        &.active {
          color: $themeTextColor;
        }
      }
    }
  }
}
</style>
