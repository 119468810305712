<template>
  <div class="page-header-box">
    <div class="header-content">
      <div class="content-header">
        <div class="time">{{headTime}}</div>
        <div class="content-header-right">
<!--          <el-input-->
<!--            class="search-input"-->
<!--            placeholder="请输入关键词"-->
<!--            size="small"-->
<!--            v-model="searchValue"-->
<!--          >-->
<!--            <template slot="append">-->
<!--              <img-->
<!--                src="@/assets/image/home/search-icon.png"-->
<!--                style="width: 19px; height: 19px"-->
<!--                alt=""-->
<!--                @click="searchClick"-->
<!--              />-->
<!--            </template>-->
<!--          </el-input>-->

<!--          <div class="email-box" @click="toLeaderEmail">-->
<!--            <img-->
<!--              class="email-icon"-->
<!--              src="@/assets/image/home/email-icon.png"-->
<!--              alt=""-->
<!--            />-->
<!--            领导信箱-->
<!--          </div>-->

<!--          <div class="header-icon-box" v-if="basicsInfo">-->
<!--            <div class="icon-outside-box">-->
<!--              <img-->
<!--                class="header-icon"-->
<!--                src="@/assets/image/home/wechat-icon.png"-->
<!--                @mouseenter="showWxCode = true"-->
<!--                @mouseleave="showWxCode = false"-->
<!--              />-->
<!--              <div class="icon-hover-box" v-show="showWxCode">-->
<!--                <img :src="basicsInfo.wxQrcode">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="icon-outside-box">-->
<!--              <img-->
<!--                class="header-icon"-->
<!--                src="@/assets/image/home/wb-icon.png"-->
<!--                @mouseenter="showWbCode = true"-->
<!--                @mouseleave="showWbCode = false"-->
<!--              />-->
<!--              <div class="icon-hover-box" v-show="showWbCode">-->
<!--                <img :src="basicsInfo.wbQrcode">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
        </div>
      </div>
      <div class="content-logo-box">
        <div class="content-logo-left">
          <img class="logo" src="@/assets/image/common/tfmz-logo.png" />
          <img class="logo-text" src="@/assets/image/common/title-logo-text.png">
<!--          <span class="logo-des">巾帼家政大数据中心</span>-->
        </div>

<!--        <img class="head-gif" src="@/assets/image/common/head-gif.gif">-->
        <span>爱心进万家 诚信树品牌</span>
      </div>
    </div>
    <div class="header-tab">
      <div
        style="height: 100%; display: flex"
        v-for="(item, index) of tabList"
        :key="index"
        @click="chooseTab(item)"
      >
        <div
          v-if="index === 0"
          style="width: 1px; height: 100%; background: #fff"
        ></div>
        <div
          class="header-tab-item"
          :class="{ active: activeTab === item.urlName }"
        >
          {{ item.label }}
        </div>

        <div style="width: 1px; height: 100%; background: #fff"></div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchList from "@/views/searchList/searchList.vue";
import moment from 'moment'
import { basicsInfo } from "@/api";
export default {
  name: "PageHeader",
  data() {
    return {
      searchValue: "",
      tabList: [
        { label: "家政政策", urlName: "OpenInfo" },
        { label: "诚信服务", urlName: "integrityServe" },
        // { label: "机构设置", urlName: "organizationSetting" },
        { label: "工作动态", urlName: "NewsTrend" },
        // { label: "机关党建", urlName: "PartyBuilding" },
        // { label: "家政政策", urlName: "OpenInfo" },
        { label: "标准体系", urlName: "ServiceCenter" },
        { label: "门店展示", urlName: "ThematicCollection" },
        { label: "数据看板", urlName: "other" },
      ],
      activeTab: "homePage",
      basicsInfo:JSON.parse(localStorage.getItem('basics')),
      showWxCode:false,
      showWbCode:false,
      headTime:''
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        localStorage.setItem("activeTab", route.name);
        this.activeTab = route.name;
        if (route.path !== '/searchList') {
          this.searchValue = ''
        }
      },
      immediate: true,
    },
  },
  mounted() {
    basicsInfo(1).then(res=>{
      if (res.code === 200) {
        this.basicsInfo = res.data
        localStorage.setItem('basics',JSON.stringify(res.data))
      }
    })

    moment.locale('zh-cn')
    this.headTime = moment().format('llll').split(' ')[0]
    const localTabActive = localStorage.getItem("activeTab");
    if (localTabActive) {
      this.activeTab = localTabActive;
    }
  },
  methods: {
    chooseTab(item) {
      if (item.label === "工作动态" || item.label === '诚信服务') {
        this.$router
          .push({ name: item.urlName, query: { sliderChildrenIndex: -1 } })
          .catch((err) => err);
      } else if (item.label !== "数据看板") {
        this.activeTab = item.urlName;
        localStorage.setItem("activeTab", item.urlName);
        this.$router.push({ name: item.urlName }).catch((err) => err);
      } else {
        window.open("https://datav.aliyun.com/share/page/168e6a36453ca59d6703fb452a94b53d", "_blank");
      }
    },
    toLeaderEmail() {
      this.$router.push({ name: "LeaderEmail" }).catch((err) => err);
    },
    searchClick() {
      if (!this.searchValue) {
        this.$message({
          type: "warning",
          message: "请输入关键词",
        });
        return;
      }
      if (this.$route.path === '/searchList') {
        this.$router.replace({path:'/Refresh',query:{title:encodeURI(this.searchValue)}})
      } else {
        this.$router.push({name:'SearchList',query:{title:encodeURI(this.searchValue)}})
      }
      // this.$router.replace({path:'/Refresh',query:{title:encodeURI(this.searchValue)}})
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/variables";

.page-header-box {
  min-width: $minPageWidth;

  .header-content {
    height: 255px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    //padding: 10px 83px 41px 360px;
    padding: 10px 0 41px 0;

    .content-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: $themeTextColor;
      padding-left: 360px;
      padding-right: 83px;

      .content-header-right {
        display: flex;
        align-items: center;

        .search-input {
          width: 306px;
        }

        .search-input .el-input-group__append {
          background: #c21101;
          border: none;
        }

        .search-input .el-input__inner {
          //width: 306px;
          border: 1px solid #c21101;
        }

        .email-box {
          @include flex-center();
          color: $themeTextColor;
          width: 112px;
          height: 32px;
          border: 1px solid #ccc;
          margin-left: 55px;
          cursor: pointer;

          .email-icon {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }

        .header-icon-box {
          @include flex-center();
          margin-left: 20px;

          .header-icon {
            width: 35px;
            height: 35px;
            cursor: pointer;

            &:last-child {
              margin-left: 5px;
            }
          }
        }
      }
    }

    .content-logo-box {
      padding-left: 179px;
      padding-right: 169px;
      display: flex;
      align-items: flex-end;
      margin-top: 67px;
      justify-content: space-between;
      span {
        font-size: 28px;
        color: $themeTextColor;
      }
      .content-logo-left {
        display: flex;
        align-items: flex-end;
        .logo {
          width: 190px;
          height: 88px;
        }
        .logo-text {
          width: 484px;
          height: 72px;
          margin-left: 3px;
        }
      }

      .head-gif {
        width: 350px;
        height: 40px;
      }
    }
  }

  .header-tab {
    //width: 100vw;
    min-width: $minPageWidth;
    background: #69B884;
    height: 80px;
    padding-left: 179px;
    padding-right: 169px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    //display: flex;
    //align-items: center;
    //justify-content: space-between;


    .header-tab-item {
      color: #fff;
      font-size: 24px;
      letter-spacing: 5px;
      font-weight: 500;
      cursor: pointer;
      height: 100%;
      width: 100%;
      //width: 195px;
      box-sizing: border-box;
      @include flex-center();
      &.active {
        font-weight: bold;
        background: linear-gradient( 180deg, #FFFFFF 0%, #E1FFE8 100%);
        color: #69B884;
        border-left: none;
      }
    }
  }
}
.icon-outside-box {
  position: relative;
  &:last-child{
    margin-left: 7px;
  }
  .icon-hover-box {
    position: absolute;
    bottom: -175px;
    left: -70px;
    padding: 14px;
    box-shadow: 0 0 8px #ccc;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-sizing: border-box;
    img {
      width: 140px;
      height: 140px;
    }
  }
}
::v-deep .el-input-group__append {
  background: $themeColor;
}
</style>
