<template>
  <!-- 页面内容 -->
  <div class="back-to-top" v-show="showBackToTop">
    <img
      src="@/assets/image/common/toTop.png"
      class="top-icon"
      @click="scrollToTop"
    />
    <!--    <a class="top" @click="scrollToTop">-->
    <!--      <span>-->
    <!--        &lt;!&ndash;        <van-icon name="arrow-up" class="i" />&ndash;&gt;-->
    <!--        顶部-->
    <!--      </span>-->
    <!--    </a>-->
  </div>
</template>
<script>
export default {
  name: "scrollTop",
  data() {
    return {
      scrollY: 0, // 当前滚动距离
      showBackToTop: false, // 是否显示返回顶部按钮
      threshold: 300, // px 滚动距离阈值
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    // 计算属性，返回当前是否应该显示返回顶部按钮
    shouldShowBackToTop() {
      return this.scrollY >= this.threshold; // 假设滚动距离超过 500px 时显示按钮
    },
  },
  watch: {
    // 监听 scrollY 属性的变化，更新 showBackToTop 属性
    scrollY(newValue) {
      this.showBackToTop = this.shouldShowBackToTop;
    },
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  right: 37px;
  bottom: 130px;
  z-index: 990;
}
.top-icon {
  width: 62px;
  height: 62px;
}
</style>
