<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page">
      <div class="content-title-box">
        <div class="content-title list-title">{{ $route.meta.pageName }}</div>
      </div>
      <div class="list-content" style="margin-top: 63px">
        <div
          class="list-content-item"
          v-for="(item, index) of 8"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="list-content-item-title">
            <div class="dot"></div>
            <span class="item-title"
              >起农民工签署20周年巴中市总工会成功调解一起农民工王毅出席庆祝《中俄睦邻友好合作条约》签署20周年巴中市总工会成功调解一起农民工签署20周年巴中市总工会成功调解一起农民工签署20周年巴中市总工会成功调解一起农民工</span
            >
          </div>
          <div class="list-content-item-time">2021/06/21</div>
        </div>
      </div>
      <MyPagination :total="100"></MyPagination>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";

export default {
  components: { MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() {
    this.breadcrumbList = [
      { title: "服务大厅", url: "/ServiceCenter" },
      { title: this.$route.meta.pageName },
    ];
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          from: encodeURI(this.$route.meta.pageName),
          url: encodeURI("/ServiceCenter/helpEnterprises"),
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
