<template>
  <div class="pagination-box">
    <el-pagination
      background
      layout="total,prev, pager, next,jumper"
      :page-size="size"
      prev-text="上一页"
      next-text="下一页"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </el-pagination>
    <div class="submit-jump">
      <div class="jump-btn">确定</div>
      <div>{{ size }}条/页</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyPagination",
  props:{
    size:{
      type:Number,
      default:8
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.pagination-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 34px;
  align-items: center;
  padding-bottom: 75px;
  .submit-jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #686868;
    margin-left: 8px;
    .jump-btn {
      width: 53px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border: 1px solid #707070;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: $themeTextColor;
}
::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.disabled, .active):hover {
  color: $themeTextColor;
}
::v-deep .el-pagination.is-background .el-pager li {
  background: #fff;
  font-size: 16px;
}
::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  background: #fff !important;
}
::v-deep .btn-prev,
.btn-next,
.el-pagination__total {
  background: #fff !important;
  font-size: 16px;
}
</style>
