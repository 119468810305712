<script>
export default {
  name: "Refresh",
  beforeRouteEnter(to,from,next) {
    next(vm=>{
      vm.$router.replace({path:from.path,query:to.query})
    })
  }
};
</script>

<template>

</template>

<style scoped lang="scss">

</style>
