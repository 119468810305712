<template>
  <div style="position: relative">
    <MyBreadcrumb :breadcrumbList="breadcrumbList"></MyBreadcrumb>
    <div class="page-content">
      <div>
        <div class="content-title-box" style="margin-top: 41px">
          <div class="content-title">主要职责</div>
        </div>
        <div class="content">
          <div class="content-title">市总工会主要职责</div>
          <div class="content-des" v-html="duty"></div>
        </div>
      </div>
      <div>
        <div class="content-title-box" style="margin: 60px 0">
          <div class="content-title">工会领导</div>
        </div>
        <div class="leader-box">
          <div class="leader-item" v-for="(item,index) in leaderList" :key="index">
            <img class="leader-img" :src="item.image">
            <div class="leader-des">
              <div class="leader-des-name">{{ item.leaderName }}</div>
              <div class="leader-des-post">
                {{ item.post }}
              </div>
              <div class="leader-des-detail">
                {{ item.briefIntroduction }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="content-title-box" style="margin: 69px 0 60px 0">
          <div class="content-title">机关部门</div>
        </div>
        <div class="department-box">
          <div
            class="department-box-item"
            v-for="(item, index) of departmentList"
            :key="index"
            @click="lookDetail(item)"
          >
            {{ item.deptName }}
          </div>
        </div>
      </div>

      <div style="margin-bottom: 82px">
        <div class="content-title-box" style="margin: 82px 0 60px 0">
          <div class="content-title">直属单位</div>
        </div>
        <div class="unit-item" v-for="(item,index) of unitList" :key="index" @click="lookDetail(item)">{{item.deptName}}</div>
      </div>
    </div>

    <div class="department-detail-dialog" v-show="showDialog">
      <div class="detail-title">{{ departmentDetail.deptName }}</div>
      <div class="detail-des">{{ departmentDetail.briefIntroduction }}</div>
      <div class="dialog-close-btn" @click="showDialog = false">关闭</div>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import {getLeaderList,getDepartmentList,getDutyList} from '@/api'
export default {
  components: {
    MyBreadcrumb,
  },
  data() {
    return {
      breadcrumbList: [],
      departmentList: [],
      leaderList:[],
      showDialog: false,
      departmentDetail: "",
      unitList:[],
      duty:''
    };
  },
  mounted() {
    this.breadcrumbList = [
      { title: this.$route.meta.pageName },
      { title: "主要职责" },
    ];
    this.getLeaderList()
    this.getDepartmentList()
    this.getDutyList()
  },
  methods: {
    lookDetail(item) {
      this.showDialog = true;
      this.departmentDetail = item;
    },
    getLeaderList() {
      getLeaderList().then(res=>{
        if (res.code === 200) {
          this.leaderList = res.data
        }
      })
    },
    getDepartmentList() {
      getDepartmentList(1).then(res=>{
        if (res.code === 200) {
          this.unitList = res.data
        }
      })
      getDepartmentList(0).then(res=>{
        if (res.code === 200) {
          this.departmentList = res.data
        }
      })
    },
    getDutyList() {
      getDutyList().then(res=>{
        console.log(res,'www')
        this.duty = res.data.list[0].content
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.page-content {
  padding: 0 180px;
  box-sizing: border-box;
  width: 100%;
  min-width: $minPageWidth;
  .content {
    margin-top: 40px;
    .content-title {
      font-size: 30px;
      color: #474747;
      font-weight: bold;
      text-align: center;
      margin-bottom: 42px;
    }
    .content-des {
      color: #686868;
      font-size: 16px;
      line-height: 55px;
    }
  }
  .leader-box {
    .leader-item {
      display: flex;

      margin-bottom: 42px;
      .leader-img {
        width: 290px;
        height: 360px;
        //background: #cfcfcf;
        margin-right: 51px;
      }
      .leader-des {
        margin-top: 24px;
        flex: 1;
        .leader-des-name {
          font-size: 30px;
          color: #474747;
        }
        .leader-des-post {
          font-size: 16px;
          color: #7f7f7f;
          margin-top: 16px;
          padding-bottom: 20px;
          border-bottom: 1px solid #f8f8f8;
        }
        .leader-des-detail {
          font-size: 16px;
          color: #686868;
          margin-top: 16px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .department-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 44px 24px;
    .department-box-item {
      height: 67px;
      background: #f8f8f8;
      text-align: center;
      line-height: 67px;
      font-size: 18px;
      color: #474747;
      cursor: pointer;
    }
  }
  .unit-item {
    cursor: pointer;
    font-size: 18px;
    color: #474747;
    width: 390px;
    height: 67px;
    text-align: center;
    line-height: 67px;
    background: #f8f8f8;
  }
}
.department-detail-dialog {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  width: 1252px;
  border-radius: 20px 20px 20px 20px;
  padding: 49px 75px 39px 75px;
  box-sizing: border-box;
  background: #fff;
  .detail-title {
    font-size: 30px;
    color: #474747;
    text-align: center;
    margin-bottom: 41px;
  }
  .detail-des {
    font-size: 16px;
    color: #686868;
    line-height: 55px;
  }
  .dialog-close-btn {
    width: 141px;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    background: #fa6c29;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
