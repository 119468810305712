<template>
  <div class="leaderEmail-page page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="content-box">
      <div class="content-top">
        <div style="display: flex; align-items: center">
          <div class="input-with-select">
            <el-input
              :placeholder="selectActive.label === '我的信件' ? '输入信件编码查询' : '输入主题' "
              v-model="searchText"
            >
              <div class="select-box" slot="prepend">
                <div
                  class="select-content"
                  @click="showSelectList = !showSelectList"
                >
                  <span>{{ selectActive.label }}</span>
                  <img
                    class="arrow-icon"
                    src="@/assets/image/LeaderEmail/arrow.png"
                  />
                </div>

                <div class="select-item-box" v-show="showSelectList">
                  <div
                    class="select-item"
                    :class="{ active: selectActive.value === item.value }"
                    v-for="(item, index) of selectList"
                    :key="index"
                    @click="chooseSelect(item)"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </el-input>
          </div>
          <div class="button-box">
            <div class="search-button" @click="searchSubmit">查询</div>
            <div class="reset-button margin-left-23" @click="resetSubmit">
              刷新
            </div>
          </div>
        </div>
        <div class="write-button" @click="toWrite">
          <img src="@/assets/image/LeaderEmail/write.png" class="write-icon" />
          <span>我要写信</span>
        </div>
      </div>

      <el-table
        class="email-table"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        :header-row-style="{
          background: '#F8F8F8',
        }"
      >
        <el-table-column prop="date">
          <template slot="header" slot-scope="scope">
            <div class="table-header table-header-first">主题</div>
          </template>
          <template slot-scope="scope">
            <div class="theme-column" @click="toDetail(scope.row)">
              {{ scope.row.consultationTopic }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" width="200">
          <template slot="header" slot-scope="scope">
            <div class="table-header">来信时间</div>
          </template>
          <template slot-scope="scope">
            <div class="table-header">{{ scope.row.createTime.split(' ')[0] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="260">
          <template slot="header" slot-scope="scope">
            <div class="table-header">办理状态</div>
          </template>
          <template slot-scope="scope">
            <div class="table-header">{{ scope.row.disposeStatus === 0 ? '待办结' : '已办结' }}</div>
          </template>
        </el-table-column>
      </el-table>
      <MyPagination :total="total"></MyPagination>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import {mailboxList} from '@/api'
export default {
  components: { MyBreadcrumb, MyPagination },
  data() {
    return {
      loading:true,
      queryParams:{
        current:1,
        size:8,
        consultationTopic:'',
        id:''
      },
      total:'',
      tableData: [],
      breadcrumbList: [],
      searchParams: {
        type: 1,
        searchText: "",
      },
      selectList: [
        { label: "我的信件", value: 1 },
        { label: "回复选登", value: 2 },
      ],
      showSelectList: false,
      selectActive: {
        label: "我的信件",
        value: 1,
      },
      searchText:''
    };
  },
  mounted() {
    this.breadcrumbList = [{ title: this.$route.meta.pageName }];
    this.getList()
  },
  methods: {
    toDetail(row) {
      this.$router.push({ name: "emailReply",query:{id:row.id} });
    },
    toWrite() {
      this.$router.push({ name: "Epistolize" });
    },
    chooseSelect(item) {
      this.selectActive = item;
      this.showSelectList = false;
    },
    searchSubmit() {
      if (this.selectActive.label === '我的信件') {
        this.queryParams.consultationTopic = ''
        this.queryParams.id = this.searchText
        if (!this.queryParams.id) {
          this.$message({
            type: "warning",
            message: "请输入信件编码",
          });
          return;
        }
        this.$router.push({ name: "emailReply",query:{id:this.queryParams.id} });
      } else {
        this.queryParams.id = ''
        this.queryParams.consultationTopic = this.searchText
        if (!this.queryParams.consultationTopic) {
          this.$message({
            type: "warning",
            message: "请输入主题内容",
          });
          return;
        }

      }
      this.getList()

    },
    resetSubmit() {
      this.queryParams={
        current:1,
        size:8,
        consultationTopic:'',
        id:''
      }
      this.getList()
    },
    getList() {
      this.loading = true
      mailboxList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
          setTimeout(()=>{
            this.loading = false
          },300)
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.leaderEmail-page {
  .content-box {
    margin-top: 61px;
    padding: 0 $pagePadding;
    box-sizing: border-box;
    .content-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-with-select {
        width: 584px;
        height: 50px;
        padding: 5px;
        box-sizing: border-box;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        .select-box {
          position: relative;

          .select-content {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: #242424;
            font-weight: bold;
          }
          .arrow-icon {
            width: 12px;
            height: 6px;
          }
          .select-item-box {
            position: absolute;
            left: -20px;
            bottom: -90px;
            background: #fff;
            width: 141px;
            padding: 8px 38px;
            box-sizing: border-box;
            font-size: 16px;
            color: #242424;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
            border-radius: 2px;
            cursor: pointer;
            z-index: 999;
            .select-item {
              padding-bottom: 9px;
              border-bottom: 1px solid #f2f2f2;
              margin-bottom: 8px;
              &.active {
                color: #c20000;
                font-weight: bold;
              }
              &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
      .button-box {
        display: flex;
        align-items: center;
        margin-left: 45px;
      }
      .write-button {
        @include flex-center();
        cursor: pointer;
        width: 141px;
        height: 50px;
        border-radius: 25px;
        background: #f8f8f8;
        font-size: 16px;
        color: #fa6c29;
        .write-icon {
          width: 23px;
          height: 18px;
          margin-right: 4px;
          padding-top: 4px;
        }
      }
      ::v-deep .el-input-group__prepend {
        width: 141px !important;
        background: #fff !important;
        box-sizing: border-box;
        border: none;
      }
      ::v-deep .el-input-group {
        width: 584px;
        border: none;
      }
      ::v-deep .el-input__inner {
        background: #f8f8f8 !important;
        border: none;
        padding-left: 30px !important;
        &:focus {
          background: #fff !important;
        }
      }
    }
    .email-table {
      margin-top: 59px;
    }
  }
}
.theme-column {
  color: #242424;
  font-size: 16px;
  font-weight: bold;
  @include ellipsis(600px);
  cursor: pointer;
}
.table-header {
  color: #999999;
  font-size: 16px;
  font-weight: bold;
}
.table-header-first {
  color: #242424;
}
.margin-left-23 {
  margin-left: 23px;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
}
::v-deep .el-table th.el-table__cell {
  border-bottom: none !important;
}
::v-deep .el-table th.el-table__cell {
  background: #f8f8f8;
}
.search-button,
.reset-button {
  width: 141px;
  height: 50px;
  background: #fa6c29;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
}
.reset-button {
  background: #f8f8f8;
  color: #fa6c29;
}
</style>
