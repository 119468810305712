import Vue from "vue";
import Vuex from "vuex";
import { getTreeList } from "@/api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serviceCenterList: [
      {
        title: "标准体系",
        egTile: "standard-system",
        list: [
          {
            label: "规范指南",
            bgImg: require("@/assets/image/serviceCenter/standard-system-1.png"),
            urlName: "BusinessHandling",
          },
          {
            label: "省级标准",
            bgImg: require("@/assets/image/serviceCenter/standard-system-2.png"),
            urlName: "LegalRegister",
          },
          {
            label: "课程体系",
            bgImg: require("@/assets/image/serviceCenter/standard-system-3.png"),
            urlName: "ContactWay",
          },
        ],
      },
    ],
    storeDisplay: [
      {
        label: "省级示范点",
        bgImg: require("@/assets/image/thematicCollection/storeDisplay-1.png"),
        urlName: "BusinessHandling",
      },
      {
        label: "居家灵活就业孵化基地",
        bgImg: require("@/assets/image/thematicCollection/storeDisplay-2.png"),
        urlName: "LegalRegister",
      },
      {
        label: "市州示范站",
        bgImg: require("@/assets/image/thematicCollection/storeDisplay-3.png"),
        urlName: "ContactWay",
      },
    ]

  },
  getters: {},
  mutations: {
  },
  actions: {

  },
  modules: {},
});
