<script>
import { nameReg, phoneReg } from "@/utils/validate";
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import { getCodeImg,verifyCaptcha,sendSmsCode,sendEmail } from "@/api";
export default {
  components: { MyBreadcrumb },
  data() {
    const checkName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人姓名"));
      } else if (!nameReg(value)) {
        callback(new Error("请正确输入中文姓名"));
      } else {
        callback();
      }
    };
    const checkPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!phoneReg(value)) {
        callback(new Error("请正确输入手机号"));
      } else {
        callback();
      }
    };
    return {
      emailForm: {
        name: "",
        consult: "",
        content: "",
        phone: "",
      },
      breadcrumbList: [],
      rules: {
        petitioner: [{ required: true, validator: checkName, trigger: "blur" }],
        consultationTopic: [
          { required: true, message: "请填写留言主题", trigger: "blur" },
        ],
        consultationContent: [
          { required: true, message: "请填写反馈内容", trigger: "blur" },
        ],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        verifyCode:[{ required: true, message: "请输入手机验证码", trigger: "blur" }]
      },
      showEmailForm: false,
      isAgree: false,
      codeDialog:false,
      codeUrl:'',
      checkCodeParams:{
        uuid:'',
        code:''
      }
    };
  },
  mounted() {
    this.breadcrumbList = [
      { title: "领导信箱", url: "/LeaderEmail" },
      { title: this.$route.meta.pageName },
    ];
    this.getCode()
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.checkCodeParams.uuid = res.uuid;
      });
    },
    resetForm() {
      this.$refs["email-form"].resetFields();
    },
    agreeHint() {
      if (!this.isAgree) {
        this.$message({
          type: "warning",
          message: "请勾选协议提示",
        });
        return;
      }
      this.showEmailForm = true;
    },
    checkCodeSubmit() {
      verifyCaptcha(this.checkCodeParams).then(res=>{
        if (res.code === 200) {
          sendSmsCode({phonenumber:this.emailForm.phone}).then(res=>{
            if (res.code === 200) {
              this.codeDialog = false
              this.$message.success('验证码发送成功')
            }
          })
        }
      })
    },
    toSend() {
      const checkPhone = phoneReg(this.emailForm.phone)
      if (checkPhone) {
        this.getCode()
        this.codeDialog = true
      } else {
        this.$message.error('请正确输入手机号码')
      }

    },
    toSendEmail() {
      this.$refs['email-form'].validate(valid=>{
        if (valid) {
          sendEmail(this.emailForm).then(res=>{
            this.$message.success('发送成功')
            this.resetForm()
          })
        }

      })
    },
  },
};
</script>

<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="hint-box" v-if="!showEmailForm">
      <div class="hint-title">领导信箱</div>
      <div class="hint-des-box">
        欢迎来到巴中市总工会网上咨询系统:<br />
        巴中市总工会网上咨询系统是巴中市总工会在互联网上设立的职工群众信访投诉渠道，主要职能是受理本市职工、群众和基层工会对涉及职工权益的法律、法规、政策咨询，对于工会工作的建议、意见，对侵犯职工权益事件的反映及要求工会帮助解决的其他事项。<br />
        为确保您所关心的问题能及时得到回复，请注意一封信
        反映一个问题或同类问题，不同类型的问题请分别反映，并详细填写联系电话和联系方式。参与信访活动，请您严格遵守中华人民共和国国务院颁布的
        <span style="color: #303030">《信访条例》</span>
      </div>
      <div class="hint-submit-box">
        <el-checkbox v-model="isAgree">我已知晓</el-checkbox>
        <div class="email-button margin-left-23" @click="agreeHint">确认</div>
      </div>
    </div>
    <div class="leaderEmail-box" v-if="showEmailForm">
      <div class="leaderEmail-box-title">领导信箱</div>
      <el-form
        label-position="right"
        :rules="rules"
        label-width="120px"
        :model="emailForm"
        ref="email-form"
      >
        <el-form-item label="姓名" prop="petitioner">
          <el-input
            v-model="emailForm.petitioner"
            placeholder="请输入职工姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="咨询主题" prop="consultationTopic">
          <el-input
            v-model="emailForm.consultationTopic"
            maxlength="50"
            placeholder="请填写留言主题(小于50字)"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="consultationContent">
          <el-input
            type="textarea"
            maxlength="200"
            placeholder="请填写您要反馈的内容(小于200字)"
            v-model="emailForm.consultationContent"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <div class="form-item-phone">
            <el-input
              v-model="emailForm.phone"
              placeholder="请输入手机号"
            ></el-input>
            <div class="email-button" style="margin-left: 16px" @click="toSend">发送验证码</div>
          </div>
        </el-form-item>
        <el-form-item label="手机验证码" prop="verifyCode">
          <el-input
            v-model="emailForm.verifyCode"
            placeholder="请输入手机验证码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="bottom-button-box">
            <div class="email-button" @click="toSendEmail">发送</div>
            <div
              class="email-button margin-left-23 reset-button"
              @click="resetForm"
            >
              重置
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="验证码"
      :visible.sync="codeDialog"
      width="30%"
      center>
      <div class="dialog-input-box">
        <el-input
          v-model="checkCodeParams.code"
          auto-complete="off"
          placeholder="验证码"
        ></el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
    <el-button @click="codeDialog = false">取 消</el-button>
    <el-button type="primary" @click="checkCodeSubmit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.hint-box {
  width: 1252px;
  height: 519px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin: 67px auto 54px auto;
  padding: 49px 76px;
  box-sizing: border-box;
  //@include flex-center();
  .hint-title {
    font-size: 30px;
    font-weight: bold;
    color: #474747;
    text-align: left;
  }
  .hint-des-box {
    margin-top: 41px;
    color: #686868;
    font-size: 16px;
    line-height: 55px;
    text-align: left;
  }
  .hint-submit-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.leaderEmail-box {
  position: relative;
  margin: 74px auto 45px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1232px;
  height: 1113px;
  background: url("@/assets/image/LeaderEmail/email-bg.png");
  background-size: cover;
  padding-top: 190px;
  padding-right: 40px;
  box-sizing: border-box;
  .leaderEmail-box-title {
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    position: absolute;
    left: 193px;
    top: 111px;
  }
  .form-item-phone,
  .bottom-button-box {
    display: flex;
    align-items: center;
  }
}
.margin-left-23 {
  margin-left: 23px;
}
.email-button,
.reset-button {
  width: 141px;
  height: 50px;
  background: #fa6c29;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
}
.reset-button {
  background: #f8f8f8;
  color: #fa6c29;
}
.dialog-input-box {
  display: flex;align-items: center;
  justify-content:center;
  .login-code {
    width: 33%;
    float: right;
    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  ::v-deep .el-input__inner {
    height: 61px;
    width: 100% !important;
  }
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #fa6c29;
  border-color: #fa6c29;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fa6c29;
}
::v-deep .el-input {
  width: auto;
}
::v-deep .el-form-item__label {
  font-size: 16px !important;
}
::v-deep .el-input__inner {
  width: 416px;
  height: 50px;
  background: #f8f8f8 !important;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    background: #fff !important;
  }
}
::v-deep .el-textarea__inner {
  width: 737px;
  height: 212px;
  background: #f8f8f8;
  font-size: 16px;
  &:focus {
    background: #fff !important;
  }
}
</style>
