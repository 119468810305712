<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page" v-if="newsList.length !== 0">
      <div class="list-content" style="margin-top: 63px">
        <div class="list-content-item" @click="toNewsDetail(item)" v-for="(item, index) of newsList" :key="index">
          <div class="list-content-item-title">
            <div class="dot"></div>
            <span class="item-title"
              >{{item.title}}</span
            >
          </div>
          <div class="list-content-item-time">{{ item.createTime.split(' ')[0] }}</div>
        </div>
      </div>
      <MyPagination :total="total" @current-change="changeCurrent"></MyPagination>
    </div>
    <Empty v-else></Empty>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import { getNewsList } from "@/api";
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty, MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      newsParams:{
        current:1,
        size:8,
        title:''
      },
      total:0,
      newsList:[]
    };
  },
  mounted() {
    const pageTitle = decodeURI(this.$route.query.title);
    this.breadcrumbList = [{ title: this.$route.meta.pageName }];
    if (this.$route.query.title) {
      this.newsParams.title = pageTitle
      this.getNewsList()
    }
  },
  methods:{
    getNewsList() {
      getNewsList(this.newsParams).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total
          this.newsList = res.data.records
        }
      });
    },
    changeCurrent(e) {
      this.newsParams.current = e
      this.getNewsList()
    },
    toNewsDetail(item) {
      if (item.newsTypeList[0].newsTypeId >= 1 && item.newsTypeList[0].newsTypeId <= 12) {
        const childTypeArr = [5,6,7,8,9,10,11]
        const checkIsChild = childTypeArr.findIndex(i=>i === item.newsTypeList[0].newsTypeId)
        if (checkIsChild !== -1) {
          this.$router.push({
            name: "NewsDetail",
            query: {
              from: encodeURI(item.newsTypeList[0].newsTypeName),
              sliderChildrenIndex: checkIsChild,
              id:item.id,
              type:item.newsTypeList[0].newsTypeId
            },
          });
        } else {
          this.$router.push({
            name: "NewsDetail",
            query: {
              from: encodeURI(item.newsTypeList[0].newsTypeName),
              sliderChildrenIndex: -1,
              id:item.id,
              type:item.newsTypeList[0].newsTypeId
            },
          });
        }
      }

      if (item.newsTypeList[0].newsTypeId === 13) {
        this.$router.push({
          name: "partyBuildingDetail",
          query: {
            from: encodeURI(item.newsTypeList[0].newsTypeName),
            url: encodeURI("/PartyBuilding"),
            type:item.newsTypeList[0].newsTypeId,
            id:item.id,
          },
        });
      }

      if (item.newsTypeList[0].newsTypeId >=14 && item.newsTypeList[0].newsTypeId<=16) {
        this.$router.push({
          name: "OpenInfoDetail",
          query: {
            from: encodeURI(item.newsTypeList[0].newsTypeName),
            id:item.id,
            type:item.newsTypeList[0].newsTypeId
          },
        });
      }

      if (item.newsTypeList[0].newsTypeId >= 17 && item.newsTypeList[0].newsTypeId <= 24) {
        const childTypeArr = [18,19,20,22,26,27,24]
        const checkIsChild = childTypeArr.findIndex(i=>i === item.newsTypeList[0].newsTypeId)
        if (checkIsChild !== -1) {
          this.$router.push({
            name: "serviceDetail",
            query: {
              from: encodeURI(item.newsTypeList[0].newsTypeName),
              url: encodeURI(`/ServiceCenter/list?type=${item.type}&pageName=${encodeURI(item.newsTypeList[0].newsTypeName)}`),
              id:item.id,
              type:item.newsTypeList[0].newsTypeId
            },
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
