import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/homePage/homePage.vue";
import organizationView from "@/views/organizationSetting/organizationSetting.vue";
import NewsTrend from "@/views/newsTrend/newsTrend.vue";
import PartyBuilding from "@/views/PartyBuilding/PartyBuilding.vue";
import OpenInfo from "@/views/OpenInfo/OpenInfo.vue";
import LeaderEmail from "@/views/LeaderEmail/LeaderEmail.vue";
import ThematicCollection from "@/views/ThematicCollection/ThematicCollection.vue";
import Epistolize from "@/views/LeaderEmail/epistolize.vue";
import emailReply from "@/views/LeaderEmail/emailReply.vue";
import ServiceCenter from "@/views/serviceCenter/serviceCenter.vue";
import UnionVideo from "@/views/unionVideo/unionVideo.vue";
import NewsDetail from "@/views/detailPage/newsDetail.vue";
import BusinessHandling from "@/views/ServiceCenterContent/businessHandling.vue";
import LegalRegister from "@/views/ServiceCenterContent/legalRegister.vue";
import ContactWay from "@/views/ServiceCenterContent/contactWay.vue";
import RelevantPolicy from "@/views/ServiceCenterContent/relevantPolicy.vue";
import HelpEnterprises from "@/views/ServiceCenterContent/helpEnterprises.vue";
import QuestionnaireSurvey from "@/views/questionnaireSurvey/questionnaireSurvey.vue";
import SearchList from "@/views/searchList/searchList.vue";
import OpenInfoDetail from "@/views/detailPage/openInfoDetail.vue";
import serviceDetail from "@/views/detailPage/serviceDetail.vue";
import partyBuildingDetail from "@/views/detailPage/partyBuildingDetail.vue";
import unionVideoDetail from "@/views/detailPage/unionVideoDetail.vue";
import thematicCollectionList from "@/views/ThematicCollection/thematicCollectionList.vue";
import thematicCollectionDetail from "@/views/detailPage/thematicCollectionDetail.vue";
import workGuide from "@/views/ServiceCenterContent/workGuide.vue";
import templateAndForm from "@/views/ServiceCenterContent/templateAndForm.vue";
import Refresh from "@/views/Refresh.vue";
import ServiceCenterList from "@/views/serviceCenter/serviceCenterList.vue";
import integrityServe from "@/views/integrityServe/integrityServe.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "OpenInfo",
    component: OpenInfo,
    meta: {
      titleName: "天府妹子官网",
      pageName: "家政政策",
    },
  },
  {
    path: "/Refresh",
    name: "Refresh",
    component: Refresh,
    meta: {
      titleName: "天府妹子官网",
    },
  },
  {
    path: "/organizationSetting",
    name: "organizationSetting",
    component: organizationView,
    meta: {
      titleName: "天府妹子官网",
      pageName: "机构设置",
    },
  },
  {
    path: "/NewsTrend",
    name: "NewsTrend",
    component: NewsTrend,
    meta: {
      titleName: "天府妹子官网",
      pageName: "工作动态",
    },
  },
  {
    path: "/integrityServe",
    name: "integrityServe",
    component: integrityServe,
    meta: {
      titleName: "天府妹子官网",
      pageName: "诚信服务",
    },
  },
  {
    path: "/PartyBuilding",
    name: "PartyBuilding",
    component: PartyBuilding,
    meta: {
      titleName: "天府妹子官网",
      pageName: "机关党建",
    },
  },
  // {
  //   path: "/OpenInfo",
  //   name: "OpenInfo",
  //   component: OpenInfo,
  //   meta: {
  //     titleName: "天府妹子官网",
  //     pageName: "家政政策",
  //   },
  // },
  {
    path: "/LeaderEmail",
    name: "LeaderEmail",
    component: LeaderEmail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "领导信箱",
    },
  },
  {
    path: "/ThematicCollection",
    name: "ThematicCollection",
    component: ThematicCollection,
    meta: {
      titleName: "天府妹子官网",
      pageName: "门店展示",
    },
  },
  {
    path: "/ThematicCollection/list",
    name: "thematicCollectionList",
    component: thematicCollectionList,
    meta: {
      titleName: "天府妹子官网",
      pageName: "门店展示",
    },
  },
  {
    path: "/LeaderEmail/epistolize",
    name: "Epistolize",
    component: Epistolize,
    meta: {
      titleName: "天府妹子官网",
      pageName: "我要写信",
    },
  },
  {
    path: "/LeaderEmail/emailReply",
    name: "emailReply",
    component: emailReply,
    meta: {
      titleName: "天府妹子官网",
      pageName: "咨询回复",
    },
  },
  {
    path: "/ServiceCenter",
    name: "ServiceCenter",
    component: ServiceCenter,
    meta: {
      titleName: "天府妹子官网",
      pageName: "标准体系",
    },
  },
  {
    path: "/ServiceCenter/list",
    name: "ServiceCenterList",
    component: ServiceCenterList,
    meta: {
      titleName: "天府妹子官网",
      pageName: "服务大厅列表",
    },
  },
  {
    path: "/ServiceCenter/businessHandling",
    name: "BusinessHandling",
    component: BusinessHandling,
    meta: {
      titleName: "天府妹子官网",
      pageName: "业务流程",
    },
  },
  {
    path: "/ServiceCenter/workGuide",
    name: "workGuide",
    component: workGuide,
    meta: {
      titleName: "天府妹子官网",
      pageName: "办事指南",
    },
  },
  {
    path: "/ServiceCenter/templateAndForm",
    name: "templateAndForm",
    component: templateAndForm,
    meta: {
      titleName: "天府妹子官网",
      pageName: "文本模版及各类表格",
    },
  },
  {
    path: "/ServiceCenter/legalRegister",
    name: "LegalRegister",
    component: LegalRegister,
    meta: {
      titleName: "天府妹子官网",
      pageName: "工会法人资格证登记",
    },
  },
  {
    path: "/ServiceCenter/contactWay",
    name: "ContactWay",
    component: ContactWay,
    meta: {
      titleName: "天府妹子官网",
      pageName: "联系方式",
    },
  },
  {
    path: "/ServiceCenter/relevantPolicy",
    name: "RelevantPolicy",
    component: RelevantPolicy,
    meta: {
      titleName: "天府妹子官网",
      pageName: "相关政策",
    },
  },
  {
    path: "/ServiceCenter/helpEnterprises",
    name: "HelpEnterprises",
    component: HelpEnterprises,
    meta: {
      titleName: "天府妹子官网",
      pageName: "助企纾困",
    },
  },
  {
    path: "/ServiceCenter/questionnaireSurvey",
    name: "QuestionnaireSurvey",
    component: QuestionnaireSurvey,
    meta: {
      titleName: "天府妹子官网",
      pageName: "问卷调查",
    },
  },
  {
    path: "/searchList",
    name: "SearchList",
    component: SearchList,
    meta: {
      titleName: "天府妹子官网",
      pageName: "搜索",
    },
  },

  {
    path: "/UnionVideo",
    name: "UnionVideo",
    component: UnionVideo,
    meta: {
      titleName: "天府妹子官网",
      pageName: "工会视频",
    },
  },
  {
    path: "/NewsDetail",
    name: "NewsDetail",
    component: NewsDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "工会视频",
    },
  },
  {
    path: "/OpenInfoDetail",
    name: "OpenInfoDetail",
    component: OpenInfoDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "详情",
    },
  },
  {
    path: "/serviceDetail",
    name: "serviceDetail",
    component: serviceDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "详情",
    },
  },
  {
    path: "/partyBuildingDetail",
    name: "partyBuildingDetail",
    component: partyBuildingDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "详情",
    },
  },
  {
    path: "/unionVideoDetail",
    name: "unionVideoDetail",
    component: unionVideoDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "详情",
    },
  },
  {
    path: "/thematicCollectionDetail",
    name: "thematicCollectionDetail",
    component: thematicCollectionDetail,
    meta: {
      titleName: "天府妹子官网",
      pageName: "详情",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.titleName;

  document.documentElement.scrollTop = 0;
  next();
});
export default router;
