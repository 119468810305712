<template>
  <div class="page-content">
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="serviceContent-page">
      <div class="content-title-box">
        <div class="content-title list-title">{{ fromTitle }}</div>
      </div>
      <div class="list-content" v-if="list.length !== 0" style="margin-top: 63px">
        <div
          class="list-content-item"
          v-for="(item, index) of list"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="list-content-item-title">
            <div class="dot"></div>
            <span class="item-title"
              >{{item.title}}</span
            >
          </div>
          <div class="list-content-item-time">{{formatTime(item.createTime)}}</div>
        </div>
      </div>
      <Empty v-else></Empty>
      <MyPagination @current-change="changeCurrent" :total="total"></MyPagination>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import MyPagination from "@/components/MyPagination.vue";
import thematicCollectionDetail from "@/views/detailPage/thematicCollectionDetail.vue";
import { getNewsList, getTopicNewsList } from "@/api";
import Empty from "@/components/Empty.vue";
import { formatTime } from "../../utils/publicMethod";
export default {
  components: { Empty, MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      fromTitle: "",
      queryParams:{
        pageSize:8,
        pageNum:1,
        type:''
      },
      list:[],
      total:0
    };
  },
  mounted() {
    this.fromTitle = decodeURI(this.$route.query.fromTitle);
    this.queryParams.type = this.$route.query.id
    this.breadcrumbList = [
      { title: "门店展示", url: "/ThematicCollection" },
      { title: this.fromTitle },
    ];
    this.getList()

  },
  methods: {
    formatTime,
    toDetail(item) {
      this.$router.push({
        name: "thematicCollectionDetail",
        query: {
          from: encodeURI(this.fromTitle),
          url: encodeURI(
            `/ThematicCollection/list?fromTitle=${decodeURI(this.fromTitle)}&id=${this.queryParams.type}`
          ),
          id:item.id,
          topicId:item.newsTypeList[0].newsTypeId
        },
      });
    },
    changeCurrent(e) {
      this.queryParams.pageNum = e
      this.getList()
    },
    getList() {
      getNewsList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
      // getTopicNewsList(this.queryParams).then(res=>{
      //   if (res.code === 200) {
      //     this.list = res.data.list
      //     this.total = res.data.total
      //   }
      // })
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

.serviceContent-page {
  padding: 0 $pagePadding;
  margin-top: 41px;
}
</style>
